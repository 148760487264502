<template>
  <b-card no-body>
    <b-card-header>
      <h5>Add a Plan</h5>
    </b-card-header>
    <b-card-body>
      <b-row class="match-height">
        <b-col cols="12">
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
            <!-- Form -->
            <b-form class="p-2" @submit.prevent="handleSubmit(addPlan)" @reset.prevent="resetForm">
              <b-row>
                <b-col cols="6">
                  <!-- Full Name -->
                  <validation-provider #default="validationContext" name="Full Name" rules="required">
                    <b-form-group label="Full Name" label-for="full-name">
                      <b-form-input id="full-name" v-model="userData.fullName" autofocus
                        :state="getValidationState(validationContext)" trim placeholder="John Doe" />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="6">
                  <!-- Planname -->
                  <validation-provider #default="validationContext" name="Planname" rules="required|alpha-num">
                    <b-form-group label="Planname" label-for="username">
                      <b-form-input id="username" v-model="userData.username"
                        :state="getValidationState(validationContext)" trim />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="6">
                  <!-- Email -->
                  <validation-provider #default="validationContext" name="Email" rules="required|email">
                    <b-form-group label="Email" label-for="email">
                      <b-form-input id="email" v-model="userData.email" :state="getValidationState(validationContext)"
                        trim />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="6">
                  <!-- Password -->
                  <validation-provider #default="validationContext" name="Password" rules="required">
                    <b-form-group label="Password" label-for="password">
                      <b-form-input id="password" v-model="userData.password"
                        :state="getValidationState(validationContext)" trim />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="6">
                  <!-- Company -->
                  <validation-provider #default="validationContext" name="Contact" rules="required">
                    <b-form-group label="Contact" label-for="contact">
                      <b-form-input id="contact" v-model="userData.contact"
                        :state="getValidationState(validationContext)" trim />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="6">
                  <!-- Company -->
                  <validation-provider #default="validationContext" name="Contact" rules="required">
                    <b-form-group label="Contact" label-for="contact">
                      <b-form-input id="contact" v-model="userData.contact"
                        :state="getValidationState(validationContext)" trim />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="6">
                  <!-- Company -->
                  <validation-provider #default="validationContext" name="Company" rules="required">
                    <b-form-group label="Company" label-for="company">
                      <b-form-input id="company" v-model="userData.company"
                        :state="getValidationState(validationContext)" trim />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="6">
                  <!-- Country -->
                  <validation-provider #default="validationContext" name="Country" rules="required">
                    <b-form-group label="Country" label-for="country" :state="getValidationState(validationContext)">
                      <v-select v-model="userData.country" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="countries" :clearable="false" input-id="country" />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="6">
                  <!-- Plan Role -->
                  <validation-provider #default="validationContext" name="Plan Role" rules="required">
                    <b-form-group label="Plan Role" label-for="user-role"
                      :state="getValidationState(validationContext)">
                      <v-select v-model="userData.role" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="roleOptions" :reduce="(val) => val.value" :clearable="false" input-id="user-role" />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="6">
                  <!-- Plan -->
                  <validation-provider #default="validationContext" name="Plan" rules="required">
                    <b-form-group label="Plan" label-for="plan" :state="getValidationState(validationContext)">
                      <v-select v-model="userData.currentPlan" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="planOptions" :reduce="(val) => val.value" :clearable="false" input-id="plan" />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <!-- Form Actions -->
              <div class="d-flex mt-2">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                  Add
                </b-button>
                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
                  @click="resetForm">
                  Cancel
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCol,
  BRow,
  BCard,
  BCardBody,
  BCardHeader,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "@/@fake-db/data/other/countries";
import store from "@/store";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BCard,
    BCardBody,
    BCardHeader,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {},
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    };
  },
  setup(props, { emit }) {
    const blankPlanData = {
      fullName: "",
      username: "",
      email: "",
      password: "",
      role: null,
      currentPlan: null,
      company: "",
      country: "",
      contact: "",
    };

    const userData = ref(JSON.parse(JSON.stringify(blankPlanData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankPlanData));
    };

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const onSubmit = () => {
      store.dispatch("app-user/addPlan", userData.value).then(() => {
        emit("refetch-data");
      });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,

      roleOptions,
      planOptions,
    };
  },

  methods: {
    addPlan() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `Plan has been added successfully!`,
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
